export default {
    name: "Bigevent",
    data() {
        return {


            LeftDistance: 0,
            RightDistance: 0,
            //底部
            bottomList: [{
                    url: require("../../assets/16.svg"),
                    text: " 2005年创立企业",
                },
                {
                    url: require("../../assets/32.svg"),
                    text: " 全国直营连锁",
                },
                {
                    url: require("../../assets/13.svg"),
                    text: " 13年连续参加世界植发大会",
                },
                {
                    url: require("../../assets/600.svg"),
                    text: " 600位医护团队",
                },
                {
                    url: require("../../assets/300.svg"),
                    text: " 16类植养方式",
                },
            ],
            CCTV_ReportedRight: [{
                    url: require("../../assets/2020.05.png"),
                    date: "2020.05",
                    title: "",
                    text: "碧莲盛董事长尤丽娜受邀参与人民网人民健康主办《两会健康特别栏目》",
                },
				{
                    url: require("../../assets/2020.09.png"),
                    date: "2020.09",
                    title: "",
                    text: "碧莲盛董事长尤丽娜受邀参与人民网人民健康品牌专访",
                },
                {
                    url: require("../../assets/2021.03.png"),
                    date: "2021.03",
                    title: "",
                    text: "碧莲盛董事长尤丽娜受邀坐客《人民会客厅·两会时刻》",
                },
				{
				    url: require("../../assets/public-welfare-assistance.png"),
				    date: "2021.06",
				    title: "",
				    text: "碧莲盛携手鸿基金发起#公益助学#活动，为山西省河津市上市小学捐赠#爱的图书馆#",
				},
            ],
            CCTV_ReportedLeft: [{
                    url: require("../../assets/sz-press-conference.png"),
                    date: "2020.08",
                    title: "",
                    text: "碧莲盛在深圳举办“碧莲盛不剃发技术发布会”",
                },
				{
				    url: require("../../assets/hz-xh-meeting.png"),
				    date: "2020.11",
				    title: "",
				    text: "碧莲盛在杭州举办“用技术创造美—碧莲盛不剃发技术西湖时尚盛典”活动",
				},
				{
				    url: require("../../assets/chengdu-hongren.png"),
				    date: "2021.04",
				    title: "",
				    text: "碧莲盛在成都举办“不剃发技术·印象红人盛典”活动",
				},
				{
				    url: require("../../assets/sanya2021.06.png"),
				    date: "2021.06",
				    title: "",
				    text: "碧莲盛举办“不剃发技术三亚星秀盛典”",
				},
            ],
            JSHRSRight: [{
                    url: require("../../assets/2008.10.png"),
                    date: "2008.10",
                    title: "蒙特利尔",
                    text: "碧莲盛参加蒙特利尔世界植发大会，与国际同业一起研讨植发新技术。",
                },
                {
                    url: require("../../assets/2010.10.png"),
                    date: "2010.10",
                    title: "波士顿",
                    text: "碧莲盛参加波士顿世界植发大会，在此次会议上，无痕植发技术受到国外同行业医生团队持续关注。",
                },
                {
                    url: require("../../assets/2012.10.png"),
                    date: "2012.10",
                    title: "巴哈马",
                    text: "碧莲盛参加巴哈马世界植发大会，受到了国内外媒体的现场追踪报道。",
                },
                {
                    url: require("../../assets/2014.10.png"),
                    date: "2014.10",
                    title: "吉隆坡",
                    text: "碧莲盛参加吉隆坡第22届世界植发大会，多国医生共同讨论植发技术。",
                },
                {
                    url: require("../../assets/2016.10.png"),
                    date: "2016.10",
                    title: "拉斯维加斯",
                    text: "碧莲盛参加拉斯维加斯世界植发大会，在会议上，董事长尤丽娜女士与国外同行热烈讨论并展望了植发技术未来的提升方向。",
                },
                {
                    url: require("../../assets/2018.10.png"),
                    date: "2018.10",
                    title: "洛杉矶好莱坞",
                    text: "碧莲盛首受邀参加了在洛杉矶好莱坞举行的第26届世界植发大会，就安全植发的问题，尤丽娜女士发表了自己的看法，引起了大会关注。",
                },
                {
                    url: require("../../assets/2020.10.png"),
                    date: "2020.10",
                    title: "不剃发植发®",
                    text: "世界植发大会受疫情影响于线上举办，碧莲盛与会上介绍了不剃发植发®技术。",
                },
            ],
            JSHRSLeft: [{
                    url: require("../../assets/2009.10.png"),
                    date: "2009.10",
                    title: "阿姆斯特丹",
                    text: "碧莲盛参加阿姆斯特丹世界植发大会，体毛移植技术备受国外毛发专家关注。",
                },
                {
                    url: require("../../assets/2011.10.png"),
                    date: "2011.10",
                    title: "阿拉斯加",
                    text: "参加阿拉斯加世界植发大会，在此次大会上碧莲盛植发技术成为关注的焦点。",
                },
                {
                    url: require("../../assets/2013.10.png"),
                    date: "2013.10",
                    title: "旧金山",
                    text: "碧莲盛在旧金山举行的世界植发大会上，与来自美国的植发机构研讨植发技术。",
                },
                {
                    url: require("../../assets/2015.10.png"),
                    date: "2015.10",
                    title: "芝加哥",
                    text: "碧莲盛参加芝加哥世界植发大会，在会议上董事长尤丽娜女士受邀上台演讲。",
                },
                {
                    url: require("../../assets/2017.10.png"),
                    date: "2017.10",
                    title: "布拉格",
                    text: "布拉格举办的第25届世界植发大会，碧莲盛带首位中国区真人案例小蔡到场，引起了现场的一致关注，碧莲盛集团董事长尤总的姓名也正式入驻世界植发大会案例中心",
                },
                {
                    url: require("../../assets/2019.10.png"),
                    date: "2019.11",
                    title: "泰国",
                    text: "本届在泰国举行的世界植发大会上，碧莲盛携真人疤痕植发案例白龙到场，向世界展示了疤痕性秃发领域的临床应用效果，并再次被世界植发大会案例库收录。",
                },
            ],
        };
    },
    methods: {
        setWidth() {
            this.$nextTick(() => {
                let n1 = this.$refs.hr.getBoundingClientRect().left
                let n2 = this.$refs.LeftDistance.getBoundingClientRect().left
                let n3 = this.$refs.RightDistance.getBoundingClientRect().left

                this.LeftDistance = n1 - n2
                this.RightDistance = n3 - n1
                console.log(this.LeftDistance);
                console.log(this.RightDistance);

            })
        }
    },

    created() {},
    mounted() {
        this.setWidth()
        window.onresize = () => {
            this.setWidth()
            return (() => {
                this.screenWidth = document.body.clientWidth;
                this.screenHeight = document.body.clientHeight;
				
            })();
        };

    }
};